* {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  overflow-y: scroll;
}

p {
  margin: 0 0 0 0;
  font-size: 0.8rem;
  font-weight: lighter;
}

.Toastify__toast-container {
  margin-top: 110px;
}

.montserrat-landing-page-font {
  * {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
  }
}

